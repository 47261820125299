import isEmpty from 'lodash/isEmpty';

import SurveyFormValues from '../types';

type FeedbackParams = {
    rating?: number;
    message?: string;
};

export const transformSearchParamsToRequestBody = (params: SurveyFormValues): FeedbackParams => {
    const {rating, message} = params;

    return {
        rating: !rating ? undefined : rating,
        message: isEmpty(message) ? undefined : message,
    };
};
