import {useDispatch} from 'react-redux';

import * as asyncActions from 'store/slices/connection/asyncActions';

const useConnectionActions = () => {
    const dispatch = useDispatch();

    return {
        getConnection: () => dispatch(asyncActions.getConnection()),
    };
};

export default useConnectionActions;
