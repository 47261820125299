import {createSlice, createEntityAdapter} from '@reduxjs/toolkit';

import isEmpty from 'lodash/isEmpty';

import {PROGRESS_STATUSES} from 'pages/Survey/constants';

import sendFeedback from './asyncActions/index';

type State = {
    status: typeof PROGRESS_STATUSES[keyof typeof PROGRESS_STATUSES];
};

const defaultState: State = {
    status: PROGRESS_STATUSES.PENDING,
};

const loadsAdapter = createEntityAdapter();

export const listSlice = createSlice({
    name: 'qualityService',
    initialState: loadsAdapter.getInitialState(defaultState),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(sendFeedback.fulfilled, (state, action) => {
            if (isEmpty(action.payload)) {
                state.status = PROGRESS_STATUSES.REJECTED;
            } else {
                state.status = PROGRESS_STATUSES.FULFILLED;
            }
        });
        builder.addCase(sendFeedback.pending, (state) => {
            state.status = PROGRESS_STATUSES.PENDING;
        });
        builder.addCase(sendFeedback.rejected, (state) => {
            state.status = PROGRESS_STATUSES.REJECTED;
        });
    },
});

export default listSlice.reducer;
