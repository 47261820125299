import React from 'react';

import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';

const Spinner: React.FC = () => {
    return (
        <Box
            sx={{
                position: 'absolute',
                top: 0,
                left: 0,
                height: '100vh',
                width: '100vw',
                textAlign: 'center',
                paddingTop: '25%',
                zIndex: '100',
            }}
        >
            <CircularProgress />
        </Box>
    );
};

export default Spinner;
