import React from 'react';
import ReactDOM from 'react-dom';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';

import CssBaseline from '@mui/material/CssBaseline';
import {createTheme, ThemeProvider} from '@mui/material/styles';

import store from './store';
import App from './App';
import reportWebVitals from './reportWebVitals';

const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    typography: {
        h4: {
            color: '#039BE5',
        },
        body1: {
            color: '#353A45',
            fontSize: '16px',
            span: {
                color: 'red',
            },
        },
        caption: {
            fontSize: '14px',
            color: '#FF5253',
        },
    },
});

ReactDOM.render(
    <CssBaseline>
        <React.StrictMode>
            <Provider store={store}>
                <ThemeProvider theme={theme}>
                    <BrowserRouter>
                        <App />
                    </BrowserRouter>
                </ThemeProvider>
            </Provider>
        </React.StrictMode>
    </CssBaseline>,
    document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
