import React, {Suspense, useEffect} from 'react';
import {Route, Switch} from 'react-router-dom';

import Spinner from '../components/ui/Spinner';
import useConnectionActions from '../hooks/useConnection/useConnectionActions';
import useConnectionState from '../hooks/useConnection/useConnectionState';

const SurveyPage = React.lazy(() => import('../pages/Survey'));
const NotFoundPage = React.lazy(() => import('../pages/NotFound'));

export default () => {
    const {getConnection} = useConnectionActions();
    const {connection} = useConnectionState();

    useEffect(() => {
        getConnection();
    }, [getConnection]);

    return (
        <Suspense fallback={<Spinner />}>
            <Switch>
                {connection.validConnection && <Route exact path="/:string" component={SurveyPage} />}
                <Route component={NotFoundPage} />
            </Switch>
        </Suspense>
    );
};
