import {AppState} from 'store';

import {createAsyncThunk} from '@reduxjs/toolkit';

import * as requests from 'pages/Survey/requests';
import {transformSearchParamsToRequestBody} from 'pages/Survey/utils';

const sendFeedback = createAsyncThunk<void, {rating?: number | null; massage?: string}, {state: AppState}>(
    'qualityService/sendFeedback',
    async (params) => {
        try {
            const hash = window.location.pathname;
            const {data} = await requests.sendFeedbackRequest(hash, transformSearchParamsToRequestBody(params));
            return data;
        } catch (error) {
            console.log('Something went wrong, place try again');
        }
    },
);

export default sendFeedback;
