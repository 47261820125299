import {createSlice, createEntityAdapter} from '@reduxjs/toolkit';

import {isEmpty} from 'lodash';

import {getConnection} from './asyncActions';

const defaultState: {validConnection: boolean; companyName: string} = {
    validConnection: false,
    companyName: '',
};

const loadsAdapter = createEntityAdapter();

export const connectionSlice = createSlice({
    name: 'connection',
    initialState: loadsAdapter.getInitialState(defaultState),
    reducers: {},
    extraReducers: (builder) => {
        builder.addCase(getConnection.fulfilled, (state, action) => {
            state.validConnection = !isEmpty(action.payload?.hash);
            state.companyName = action.payload?.company_name;
        });
        builder.addCase(getConnection.rejected, (state) => {
            state.validConnection = false;
        });
    },
});

export default connectionSlice.reducer;
