import {createAsyncThunk} from '@reduxjs/toolkit';

import {checkConnection} from 'services/connection';

const getConnection = createAsyncThunk('connection/getConnection', async () => {
    const hash = window.location.pathname;
    const {data} = await checkConnection(hash);
    return data;
});

export {getConnection};
